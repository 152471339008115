import React, { useEffect, useState } from 'react';
import { Sketch } from '@uiw/react-color';
import html2canvas from 'html2canvas';
import $ from 'jquery';

//Creating dynamic link that automatically click
const downloadURI = (uri, name) => {
  var link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  //after creating link you should delete dynamic link
  //clearDynamicLink(link);
};

//Your modified code.
const printToFile = () => {
  let div = $(`#colors-result-image`).get(0);
  html2canvas(div).then(canvas => {
    var myImage = canvas.toDataURL('image/png');
    //create your own dialog with warning before saving file
    //beforeDownloadReadMessage();
    //Then download file
    downloadURI('data:' + myImage, 'colors_result_image.png');
  });
};

const hexToRgb = hex => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};

const isDarkColor = color => {
  const rgb = hexToRgb(color);
  const Y = 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b;
  const darkColor = Y < 128 ? true : false;
  return darkColor;
};

const ColorsProject = () => {
  const [colors, setColors] = useState([
    { color: '#0000FF', hovering: false },
    { color: '#FF0000', hovering: false }
  ]);
  const [colorsString, setColorsString] = useState('#0000FF, #FF0000');
  const [style, setStyle] = useState('linear');

  useEffect(() => {
    let s = '';
    colors.forEach(c => {
      s += ', ' + c.color;
    });
    setColorsString(s);
  }, [colors]);

  const renderColorSelectors = () => {
    let colorSelectors = colors.map((c, i) =>
      c.hovering ? (
        <div className="color-wheel-container" key={`colors-project-color-wheel-${i}`}>
          <Sketch
            color={colors[i].color}
            onChange={color => {
              changeColor(i, color.hex);
            }}
            onMouseLeave={() => hideColorWheel(i)}
          />
        </div>
      ) : (
        <div
          className="color-selector"
          key={`colors-project-color-selector-${i}`}
          style={{
            backgroundColor: c.color,
            color: isDarkColor(c.color) ? 'white' : 'black'
          }}
          onMouseOver={() => showColorWheel(i)}
        >
          {c.color}
        </div>
      )
    );
    colorSelectors.push(
      <div className="color-selector" key={`colors-project-color-selector-add`}>
        <div
          className="color-selector-add"
          style={{ backgroundColor: '#FFFFFF', color: 'black' }}
          onClick={() => setColors([...colors, { color: '#FFFFFF', hovering: false }])}
        >
          Add
        </div>
      </div>
    );
    return colorSelectors;
  };

  const showColorWheel = i => {
    let cs = [...colors];
    if (!cs[i].hovering) cs[i].hovering = true;
    setColors(cs);
  };

  const hideColorWheel = i => {
    let cs = [...colors];
    if (cs[i].hovering) cs[i].hovering = false;
    setColors(cs);
  };

  const changeColor = (i, color) => {
    let cs = [...colors];
    cs[i].color = color;
    setColors(cs);
  };

  return (
    <div className="colors-project-page">
      <div className="color-selectors-container">{renderColorSelectors()}</div>
      <div className="colors-result-section">
        <div className="colors-result-options-container">
          <div className="colors-result-option" onClick={() => setStyle('radial')}>
            Radial
          </div>
          <div className="colors-result-option" onClick={() => setStyle('linear')}>
            Linear
          </div>
        </div>
        <div
          className="colors-result-image"
          id="colors-result-image"
          style={{
            background:
              style === 'linear'
                ? `linear-gradient(0deg${colorsString})`
                : `radial-gradient(circle at center${colorsString})`
          }}
        ></div>
        <div className="colors-result-download-button-container">
          <div className="colors-result-download-button" onClick={printToFile}>
            Download
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorsProject;
