import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div className="not-found-page">
      <div className="not-found-container">
        <div className="not-found-text">Oops. That page was not found.</div>
        <Link to="/">
          <div className="not-found-button">Click here to go to the home page</div>
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
