import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const api = '/auth';

export const signup = createAsyncThunk(
  'authReducer/signup',
  async (signupData, thunkAPI) => {
    try {
      const res = await axios.post(`${api}/signup`, signupData);
      const user = res.data;
      return user;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.errors);
    }
  }
);

export const login = createAsyncThunk(
  'authReducer/login',
  async (loginData, thunkAPI) => {
    try {
      const res = await axios.post(`${api}/login`, loginData);
      const user = res.data;
      return user;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.errors);
    }
  }
);

export const logout = createAsyncThunk('authReducer/logout', async (args, thunkAPI) => {
  try {
    const res = await axios.post(`${api}/logout`);
    const data = res.data;
    return data;
  } catch (err) {
    throw Error(err);
    // return thunkAPI.rejectWithValue(err.response.data.errors);
  }
});

export const checkUser = createAsyncThunk(
  'authReducer/checkUser',
  async (args, thunkAPI) => {
    try {
      const res = await axios.post(`${api}/check`);
      const user = res.data;
      return user;
    } catch (err) {
      return thunkAPI.rejectWithValue(undefined);
    }
  }
);

const authSlice = createSlice({
  name: 'authReducer',
  initialState: {
    user: null,
    status: 'unchecked', // no user, has user, logged out, logged in
    errors: {}
  },
  reducers: {
    removeErrors: (state, action) => {
      state.errors = {};
    }
  },
  extraReducers: builder => {
    builder
      .addCase(checkUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = 'has user';
      })
      .addCase(checkUser.rejected, (state, action) => {
        state.user = null;
        state.status = 'no user';
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.user = action.payload;
        state.errors = {};
        state.status = 'logged in';
      })
      .addCase(signup.rejected, (state, action) => {
        state.user = null;
        state.errors = action.payload;
        state.status = 'no user';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload;
        state.errors = {};
        state.status = 'logged in';
      })
      .addCase(login.rejected, (state, action) => {
        state.user = null;
        state.errors = action.payload;
        state.status = 'no user';
      })
      .addCase(logout.fulfilled, (state, action) => {
        // ought to be a better way of doing this, this is all about ProtectedRoute
        state.user = null;
        state.status = 'logged out';
      });
  }
});

export const { removeErrors } = authSlice.actions;

export default authSlice.reducer;
