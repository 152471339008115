import React, { useEffect, useRef, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const timeFormat = milliseconds => {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  milliseconds = Math.floor((milliseconds % 1000) / 10);
  seconds = seconds % 60;
  minutes = minutes % 60;

  let display = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${milliseconds
    .toString()
    .padStart(2, '0')}`;

  return display;
};

const calcGoal = (num, unit) => {
  let multiple = unit === 's' ? 1 : unit === 'min' ? 60 : 60 * 60;
  let goal = num * 1000 * multiple;
  return goal;
};

const StopwatchBox = props => {
  const [time, setTime] = useState(0);
  const timeRef = useRef(time);
  timeRef.current = time;

  const [timeStarted, setTimeStarted] = useState(0);
  const timeStartedRef = useRef(timeStarted);
  timeStartedRef.current = timeStarted;

  const [timeDisplay, setTimeDisplay] = useState('00:00:00:00');
  const [isRunning, setIsRunning] = useState(false);

  const [label, setLabel] = useState('Label');
  const [goalNum, setGoalNum] = useState(1);
  const [goalUnit, setGoalUnit] = useState('min');

  const [goal, setGoal] = useState(1000 * 60);
  const goalRef = useRef(goal);
  goalRef.current = goal;

  useEffect(() => {
    setGoal(calcGoal(goalNum, goalUnit));
  }, [goalNum, goalUnit]);

  useEffect(() => {
    let intervalId;
    if (isRunning) {
      setTimeStarted(new Date().getTime() - timeRef.current);

      const theFunction = () => {
        const timeSinceEpoch = new Date().getTime();
        const newTime = timeSinceEpoch - timeStartedRef.current;

        if (newTime > goalRef.current) {
          setIsRunning(false);
          setTime(goalRef.current);
          return;
        }
        setTime(newTime);
      };

      // theFunction();
      intervalId = setInterval(theFunction, 10);
    }
    return () => clearInterval(intervalId);
  }, [isRunning]);

  useEffect(() => {
    setTimeDisplay(timeFormat(time));
  }, [time]);

  const reset = () => {
    setTime(0);
    setTimeStarted(0);
    setIsRunning(false);
  };

  return (
    <div className="stopwatch-box">
      <div className="stopwatch-label">
        <input
          type="text"
          value={label}
          onChange={e => setLabel(e.target.value)}
          name="label"
          maxLength={20}
        ></input>
      </div>

      <div className="stopwatch-container">
        <div className="stopwatch-times">
          <div className="stopwatch-current">{timeDisplay}</div>
          <div className="stopwatch-goal">
            <input
              type="text"
              value={goalNum}
              onChange={e =>
                timeRef.current === 0
                  ? setGoalNum(e.target.value)
                  : calcGoal(e.target.value, goalUnit) >= timeRef.current
                  ? setGoalNum(e.target.value)
                  : null
              }
              name="goalNum"
              maxLength={3}
            ></input>{' '}
            <input
              type="text"
              value={goalUnit}
              onChange={e =>
                e.target.value === 'hr' ||
                e.target.value === 'min' ||
                e.target.value === 's'
                  ? timeRef.current === 0
                    ? setGoalUnit(e.target.value)
                    : calcGoal(goalNum, e.target.value) >= timeRef.current
                    ? setGoalUnit(e.target.value)
                    : null
                  : null
              }
              name="goalUnit"
              maxLength={3}
            ></input>
          </div>
        </div>
        <div className="stopwatch">
          <Doughnut
            data={{
              labels: ['Done', 'Remaining'],
              datasets: [
                {
                  data: [timeRef.current, goalRef.current - timeRef.current],
                  backgroundColor: ['#33006F', '#D8BFD8']
                }
              ]
            }}
            options={{ plugins: { legend: { display: false } }, animation: false }}
            height={200}
            width={200}
          ></Doughnut>
        </div>
      </div>

      <div className="stopwatch-buttons">
        <div
          className="stopwatch-button stopwatch-start"
          onClick={() => setIsRunning(true)}
        >
          Start
        </div>
        <div
          className="stopwatch-button stopwatch-stop"
          onClick={() => setIsRunning(false)}
        >
          Stop
        </div>
        <div className="stopwatch-button stopwatch-lap" onClick={reset}>
          Reset
        </div>
      </div>
    </div>
  );
};

export default StopwatchBox;
