import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const api = '/api/projects';

export const fetchProjects = createAsyncThunk(
  'projectReducer/fetchProjects',
  async () => {
    try {
      const res = await axios.get(api);
      const projects = res.data.projects;
      return projects;
    } catch (err) {
      throw Error(err);
    }
  }
);

export const fetchProject = createAsyncThunk('projectReducer/fetchProject', async id => {
  try {
    const res = await axios.get(`${api}/${id}`);
    const project = res.data;
    return project;
  } catch (err) {
    throw Error(err);
  }
});

const projectSlice = createSlice({
  name: 'projectReducer',
  initialState: { projects: [] },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.projects = action.payload;
      })
      .addCase(fetchProject.fulfilled, (state, action) => {
        state.selectedProject = action.payload;
      });
  }
});

export default projectSlice.reducer;
