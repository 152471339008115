import img1 from '../../../assets/blog_3_img_1.png';

export default {
  _id: Math.random().toString(),
  title: 'Journal Entry: Daily Project #3',
  subtitle: 'Onwards and Upwards',
  dates: {
    published: 'Tuesday June 6th, 2023',
    started: 'Tuesday June 6th, 2023',
    lastUpdated: 'Tuesday June 6th, 2023'
  },
  body: [
    {
      html: `It’s Tuesday and I’m going to try to get a little project done right now so I can squeeze in a nap before my 4-hour class later. I like writing fiction so I’ve decided to create a page where you can input different scene ideas with a rating from 1 through 5, and the page randomizes the order for you to help you think of the order of your plot and/or to figure out what you’re missing/adding. The rating, I’m thinking, will be in regards to how slow or fast the pace of the scene is, but technically the user can connect the rating to a scale of their liking, like how funny the scene is or how scary.`
    },
    {
      html: `Let’s get started, shall we?`
    },
    {
      html: `...`
    },
    ,
    { html: `All done… to an extent. I've also added a subpar download option.` },
    { html: `<img src=${img1} alt="blog_3_img_1" />` },
    {
      html: `(Find it on <a href="https://www.manahilsher.com/plot">manahilsher.com/plot</a>.)`
    }
  ]
};
