import React from 'react';

import {
  EmailShareButton,
  EmailIcon,
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  PinterestShareButton,
  PinterestIcon
} from 'react-share';

const Article = props => {
  const parser = new DOMParser();

  const renderBody = () => {
    let paragraphs = [];
    props.article.body.forEach((p, i) => {
      paragraphs.push(
        <div
          className={`article-body-paragraph ${p.gif ? `gif-insert` : ``}`}
          key={`paragraph-${i}-${props.article._id}`}
          dangerouslySetInnerHTML={{ __html: p.html ? p.html : p.gif }}
        ></div>
      );
    });
    return paragraphs;
  };

  return (
    <article className="article">
      <h1 className="article-title">{props.article.title}</h1>
      <h2>{props.article.subtitle}</h2>
      <h4 className="date">{props.article.dates.published}</h4>

      <div className="article-socials-container">
        <EmailShareButton url="https://www.manahilsher.com">
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
        <TwitterShareButton url="https://www.manahilsher.com">
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
        <FacebookShareButton url="https://www.manahilsher.com">
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
        <PinterestShareButton url="https://www.manahilsher.com">
          <PinterestIcon size={32} round={true} />
        </PinterestShareButton>
      </div>

      <div className="article-body">{renderBody()}</div>
    </article>
  );
};

export default Article;
