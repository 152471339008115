import React from 'react';
import { Link } from 'react-router-dom';

const Home = props => {
  return (
    <div className="home-page">
      {/* <div>Home</div> */}
      <div className="home-menu-items">
        <div className={`home-menu-item`}>
          <Link to={`/blog`}>
            <i className={`fa-solid fa-pen-to-square fa-10x c-red-normal`}></i>
          </Link>
        </div>
        <div className={`home-menu-item`}>
          <Link to={`/projects`}>
            <i className={`fa-solid fa-gears fa-10x c-blue-normal`}></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
