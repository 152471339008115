import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <div className="header">
      <Link to="/" className="header-logo">
        <div className={`c-purple-superdark`}>MS</div>
      </Link>
      <div className="header-title">Manahil Sher</div>
      <div className="header-link">
        <a href="https://www.linkedin.com/in/manahilsher" target="_blank">
          <i className={`fa-brands fa-github fa-xl c-purple-superdark`}></i>
        </a>
        <a href="https://www.linkedin.com/in/manahilsher" target="_blank">
          <i className={`fa-brands fa-linkedin fa-xl c-purple-superdark`}></i>
        </a>
        <a href="https://www.youtube.com/@manahilsher" target="_blank">
          <i className={`fa-solid fa-camera fa-xl c-purple-superdark`}></i>
        </a>
      </div>
    </div>
  );
};

export default Header;
