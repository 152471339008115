import React, { useState } from 'react';
import html2canvas from 'html2canvas';
import $ from 'jquery';

//Creating dynamic link that automatically click
const downloadURI = (uri, name) => {
  var link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  //after creating link you should delete dynamic link
  //clearDynamicLink(link);
};

//Your modified code.
const printToFile = () => {
  let div = $(`#scenes-container`).get(0);
  html2canvas(div).then(canvas => {
    var myImage = canvas.toDataURL('image/png');
    //create your own dialog with warning before saving file
    //beforeDownloadReadMessage();
    //Then download file
    downloadURI('data:' + myImage, 'full_plot.png');
  });
};

const PlotProject = () => {
  const [newScene, setNewScene] = useState('');
  const [newRating, setNewRating] = useState(1);
  const [scenes, setScenes] = useState([]);

  const handleNewSceneChange = e => {
    setNewScene(e.target.value);
  };

  const handleNewRatingChange = e => {
    setNewRating(e.target.value);
  };

  const addScene = () => {
    setScenes([...scenes, { scene: newScene, rating: newRating }]);
    setNewScene('');
  };

  const renderScenes = () => {
    const ss = scenes.map((s, i) => (
      <div className="scene-container" key={`scene-${i}`}>
        <div className="scene">{s.scene}</div>
        <div className="rating">{s.rating}</div>
      </div>
    ));
    return ss;
  };

  const shuffle = () => {
    let ss = [...scenes];
    for (let i = ss.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = ss[i];
      ss[i] = ss[j];
      ss[j] = temp;
    }
    setScenes(ss);
  };

  return (
    <div className="plot-project-page">
      <div className="inputs-container">
        <input
          className="scene-input"
          name="newScene"
          value={newScene}
          onChange={handleNewSceneChange}
        ></input>
        <input
          className="rating-input"
          type="number"
          name="newRating"
          value={newRating}
          onChange={handleNewRatingChange}
        ></input>
        <div className="add-scene" onClick={addScene}>
          Add
        </div>
      </div>
      <div className="scenes-container" id="scenes-container">
        {renderScenes()}
      </div>
      <div className="buttons-container">
        <div className="shuffle" onClick={shuffle}>
          Shuffle
        </div>
        <div
          className="download"
          onClick={scenes.length > 0 && scenes.length < 8 ? printToFile : null}
          style={{
            cursor: scenes.length > 0 && scenes.length < 8 ? 'pointer' : 'default'
          }}
        >
          Download
        </div>
      </div>
    </div>
  );
};

export default PlotProject;
