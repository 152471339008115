import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './styles/index.scss';

import HomePage from './pages/HomePage';
import BlogPage from './pages/BlogPage';
import ProjectsPage from './pages/ProjectsPage';

import styles from './styles/_themes.scss';

import { setThemes } from './store/commonSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import NotFoundPage from './pages/NotFoundPage';
import AuthPage from './pages/AuthPage';
import ShopPage from './pages/ShopPage';
// import { checkUser } from './store/authSlice';
import PageLayout from './layout/PageLayout';
import StopwatchProject from './features/projects/stopwatches/StopwatchProject';
import ColorsProject from './features/projects/colors/ColorsProject';
import PlotProject from './features/projects/plot/PlotProject';

const App = () => {
  const dispatch = useDispatch();

  const themes = useSelector(state => state.commonReducer.themes);
  // const user = useSelector(state => state.authReducer.user);

  useEffect(() => {
    // This is for getting the themes from the sass file
    let retrievedThemes = {};
    Object.keys(styles).forEach(s => {
      // remove outer quotes, idk how it gets there but oh well
      let readyToParse = styles[s].slice(1, styles[s].length - 1);
      const parsed = JSON.parse(readyToParse);
      retrievedThemes[s] = parsed;
    });

    dispatch(setThemes(retrievedThemes));
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(checkUser());
  // }, [dispatch]);

  return (
    <div className="App">
      <BrowserRouter>
        {themes ? (
          <Routes>
            {/* <Route exact path="/auth" element={<AuthPage />}></Route> */}

            <Route element={<PageLayout />}>
              <Route exact path="/" element={<HomePage />}></Route>
              <Route exact path="/blog" element={<BlogPage />}></Route>
              <Route exact path="/projects" element={<ProjectsPage />}></Route>
              {/* <Route exact path="/shop" element={<ShopPage />}></Route> */}

              {/* projects */}
              <Route exact path="/stopwatches" element={<StopwatchProject />}></Route>
              <Route exact path="/colors" element={<ColorsProject />}></Route>
              <Route exact path="/plot" element={<PlotProject />}></Route>
            </Route>

            <Route exact path="/page-not-found" element={<NotFoundPage />}></Route>
            <Route exact path="/*" element={<NotFoundPage />}></Route>
          </Routes>
        ) : null}
      </BrowserRouter>
    </div>
  );
};

export default App;
