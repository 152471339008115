import img1 from '../../../assets/blog_1_img_1.png';

export default {
  _id: Math.random().toString(),
  title: 'Journal Entry: Daily Project #1',
  subtitle: 'I Did/Am Doing a Thing',
  dates: {
    published: 'Saturday June 3rd, 2023',
    started: 'Sunday April 2nd, 2023',
    lastUpdated: 'Saturday June 3rd, 2023'
  },
  body: [
    {
      html: `It's Saturday and I start by cheating. Is it cheating when I'm in charge though?`
    },
    {
      html: `Anyway, I wanted to get this project done in a day. I did complete a lot of it that first day back in April when I set out on this endeavor, and I did work on it for a few days after that (Besides, I planned for some leeway in the whole one-day thing before I began).`
    },
    {
      html: `Then life happened as it so often does (I need to work on not letting life stop me from… living? If that makes sense?). I decided again on June 3rd (aka today) to complete one project in one day. For today I choose that original project, which I already spent a few days on, to complete. To be as unforgiving as I can to my perfectionism, I'm going to call this project "done" even though my heart doesn't believe it's done (my brain doesn't either, actually). And to be ferocious against my procrastination, I am writing these words down in stone, and I forbid myself from brainstorming the next sentence or from erasing the previous sentence.`
    },
    ,
    { html: `So shall we do the honors?` },
    { html: `<b><i>"Done."</i></b>` },
    { html: `<img src=${img1} alt="blog_1_img_1" />` },
    {
      html: `(Find it on <a href="https://www.manahilsher.com/stopwatches">manahilsher.com/stopwatches</a>.)`
    }
  ]
};
