import img1 from '../../../assets/blog_2_img_1.png';

export default {
  _id: Math.random().toString(),
  title: 'Journal Entry: Daily Project #2',
  subtitle: 'Two Days Later',
  dates: {
    published: 'Monday June 5th, 2023',
    started: 'Monday June 5th, 2023',
    lastUpdated: 'Monday June 5th, 2023'
  },
  body: [
    {
      html: `It's Monday afternoon and I decide I'm going to get 2 projects done in the next few hours since I missed yesterday. I'll have to brainstorm what these projects are.`
    },
    {
      html: `...`
    },
    {
      html: `For the first project I've decided to create something where the user chooses a few colors and then can click a button to generate a spiral or linear image with those colors, which they can then download. Let's see how it goes.`
    },
    {
      html: `...`
    },
    ,
    { html: `Done :) <i>(at approximately 2.5 hours)</i>` },
    { html: `<img src=${img1} alt="blog_2_img_1" />` },
    {
      html: `(Find it on <a href="https://www.manahilsher.com/colors">manahilsher.com/colors</a>.)`
    },
    { html: `Not sure if I'll get to the second project :)` }
  ]
};
