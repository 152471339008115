import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import articles from '../api/articles';

const api = '/api/articles';

export const fetchArticles = createAsyncThunk(
  'articleReducer/fetchArticles',
  async () => {
    try {
      // const res = await axios.get(api);
      // const articles = res.data;
      return articles;
    } catch (err) {
      throw Error(err);
    }
  }
);

export const fetchArticle = createAsyncThunk('articleReducer/fetchArticle', async _id => {
  try {
    // const res = await axios.get(`${api}/${id}`);
    // const article = res.data;

    const article = articles.find(a => a._id === _id);
    return article;
  } catch (err) {
    throw Error(err);
  }
});

// export const postArticle = createAsyncThunk(
//   'authReducer/postArticle',
//   async (articleData, thunkAPI) => {
//     try {
//       const res = await axios.post(`${api}`, articleData);
//       const article = res.data;
//       return article;
//     } catch (err) {
//       throw Error(err);
//     }
//   }
// );

const articleSlice = createSlice({
  name: 'articleReducer',
  initialState: { articles: [] },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchArticles.fulfilled, (state, action) => {
        state.articles = action.payload;
      })
      .addCase(fetchArticle.fulfilled, (state, action) => {
        state.selectedArticle = action.payload;
      });
  }
});

export default articleSlice.reducer;
