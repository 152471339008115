import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Article from '../features/blog/components/Article';

import { fetchArticles } from '../store/articleSlice';
import AdminArticle from '../features/blog/components/AdminArticle';

const BlogPage = props => {
  const dispatch = useDispatch();

  let articles = useSelector(state => state.articleReducer.articles);

  useEffect(() => {
    dispatch(fetchArticles());
  }, [dispatch]);

  const renderArticles = articles => {
    let articlesEl = [];
    // const admin = false; // REMOVE BEFORE COMMIT

    for (let i = articles.length - 1; i >= 0; i--) {
      false
        ? articlesEl.push(<AdminArticle article={articles[i]} key={articles[i]._id} />)
        : articlesEl.push(<Article article={articles[i]} key={articles[i]._id} />);
    }
    return articlesEl;
  };

  return (
    <div className="blog-page">
      <div className="blog">{articles ? renderArticles(articles) : null}</div>
    </div>
  );
};

export default BlogPage;
