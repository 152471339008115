import React from 'react';
import { Link } from 'react-router-dom';

const ProjectsPage = () => {
  return (
    <div className="projects-page">
      <div className="projects">
        <div className={`project-card`}>
          <Link to={`/stopwatches`}>
            <i
              className={`fa-solid fa-stopwatch fa-10x`}
              style={{ color: `#547eb5` }}
            ></i>
          </Link>
        </div>
        <div className={`project-card`}>
          <Link to={`/colors`}>
            <i
              className={`fa-solid fa-paint-roller fa-10x`}
              style={{ color: `#6ac9c9` }}
            ></i>
          </Link>
        </div>
        <div className={`project-card`}>
          <Link to={`/plot`}>
            <i className={`fa-solid fa-list fa-10x`} style={{ color: `#f1e15e` }}></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectsPage;
