import React from 'react';
import StopwatchBox from './components/StopwatchBox';

const StopwatchProject = () => {
  return (
    <div className="stopwatch-project-page">
      <div className="stopwatches">
        <StopwatchBox id={1} />
        <StopwatchBox id={2} />
        <StopwatchBox id={3} />
        <StopwatchBox id={4} />
        <StopwatchBox id={5} />
        <StopwatchBox id={6} />
      </div>
    </div>
  );
};

export default StopwatchProject;
