import { createSlice } from '@reduxjs/toolkit';

const commonSlice = createSlice({
  name: 'commonReducer',
  initialState: {
    themes: {}
  },
  reducers: {
    setThemes: (state, action) => {
      state.themes = action.payload;
    }
  }
});

export const { setThemes } = commonSlice.actions;

export default commonSlice.reducer;
