import { configureStore } from '@reduxjs/toolkit';
import reduxLogger from 'redux-logger';

import authReducer from './authSlice';
import articleReducer from './articleSlice';
import commonReducer from './commonSlice';
import projectReducer from './projectSlice';

const reducers = { authReducer, articleReducer, commonReducer, projectReducer };

const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(reduxLogger)
});

export default store;
